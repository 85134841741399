<template>
  <div style="margin-bottom: 300px">
    <v-row class="text-center mt-2">
      <v-col cols="2" md="4"> </v-col>
      <v-col cols="8" md="4">
        <h2 style="color: white; border: solid 1px">รายละเอียดการสั่งซื้อ</h2>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="pa-2" v-if="tranxDetail.user">
      <v-card
        class="pa-8 mt-4"
        style="max-width: 700px; height: auto; width: 95%"
      >
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="12">
            <span style="font-size: 24px">
              <b>ชื่อผู้สั่งซื้อ: </b>
              {{ tranxDetail.user.firstName + " " + tranxDetail.user.lastName }}
            </span>
          </v-col>
          <v-col cols="12" md="12">
            <span style="font-size: 24px">
              <b
                >เบอร์โทร:
                <span style="color: #4ca3ad">{{
                  tranxDetail.user.telNumber
                }}</span>
              </b>
            </span>
          </v-col>
          <v-col cols="12" md="12">
            <span style="font-size: 24px">
              <b
                >เลขที่อ้างอิง:
                <span style="color: #4ca3ad">{{
                  tranxDetail.transactionNo
                }}</span>
              </b>
            </span>
          </v-col>
          <v-col cols="12" md="12">
            <span style="font-size: 24px">
              <b
                >สถานะ:
                <!-- <span style="color: #008080">{{ tranxDetail.status }}</span> -->
                <span v-if="tranxDetail.status == 'PENDING'">
                  <span style="color: #647d87">รอการชำระเงิน</span>
                </span>
                <span v-if="tranxDetail.status == 'รอดำเนินการ'">
                  <span style="color: #008080">รอดำเนินการ</span>
                </span>
                <span v-if="tranxDetail.status == 'SUCCESS'">
                  <span style="color: green">อนุมัติ/ยืนยันสลิปถูกต้อง</span>
                </span>
                <span v-if="tranxDetail.status == 'มีปัญหา'">
                  <span style="color: red">มีปัญหา</span>
                </span>
                <span v-if="tranxDetail.status == 'ยกเลิก'">
                  <span style="color: red">ยกเลิก</span>
                </span>
              </b>
            </span>
          </v-col>
          <v-col cols="12" md="12" v-if="tranxDetail.isWin">
            <b
              >เงินรางวัลรวม:
              <span style="color: #e1c565; font-size: 26px">{{
                tranxDetail.winPrice
              }}</span>
              บาท
            </b>
          </v-col>
          <v-col
            cols="12"
            md="12"
            v-if="tranxDetail.isWin"
            class="text-center mt-4"
          >
            <v-card style="background-color: red" class="pa-4"
              ><span
                v-if="!tranxDetail.user.accountName"
                style="color: white; font-size: 20px"
                >กรุณาเพิ่มเลขที่บัญชีธนาคาร
                <br />
                คลิกที่ปุ่มด้านล่าง
                <br />
              </span>
              <v-btn
                v-if="!tranxDetail.user.accountName"
                block
                class="mt-2 mb-2"
                style="color: black"
                @click="goToProfile()"
                >เพิ่มบัญชี</v-btn
              >
              <span style="color: white; font-size: 20px"
                >เงินรางวัลจะเข้าบัญชีธนาคาร <br />
                ภายใน 3-5 ชม. <br />
                (หรือไม่เกิน 24 ชม.
                ในกรณีที่ลูกค้ากรอกข้อมูลไม่ครบถ้วนหรือไม่ถูกต้อง)</span
              >
            </v-card>
          </v-col>

          <!-- <v-col cols="12" md="12">
            <span style="font-size: 24px">
              <b>ชำระเงินเมื่อ: </b>
              {{ convertDate(tranxDetail) }}
            </span>
          </v-col> -->
        </v-row>

        <v-row>
          <v-col cols="12" md="12" sm="12"
            ><v-col cols="12" md="4" sm="12" class="right">
              <v-text-field
                v-model="search"
                dense
                hide-details
                outlined
                placeholder="ค้นหาหมายเลขลอตเตอรี่"
              ></v-text-field> </v-col
          ></v-col>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="tranxDetail.lotteryTransaction"
              :search="search"
              :items-per-page="10"
              disable-sort
              hide-default-footer
              disable-pagination
              no-data-text="ไม่มีข้อมูล"
              class="elevation-1"
            >
              <template v-slot:[`item.count`]="{ index }">
                <!-- <span>{{ item.count.indexOf() + 1 }}</span> -->
                <span>{{ index + 1 }}</span>
              </template>

              <!-- <template v-slot:[`item.updatedAt`]="{ item }">
                {{ convertDate(item) }}
              </template> -->
              <template v-slot:[`item.lottery.urlImage`]="{ item }">
                <v-row>
                  <v-img
                    :src="item.lottery.urlImage"
                    lazy="loaded"
                    max-height="250px"
                    max-width="300px"
                  />
                  <div class="info2">
                    <v-img max-width="80px" class="mx-auto mb-2"></v-img>
                  </div>
                </v-row>
              </template>
              <template v-slot:[`item.winningPrize`]="{ item }">
                <span
                  v-if="item.lottery.winningPrize == 'ไม่ถูกรางวัล'"
                  style="color: red"
                  >{{ item.lottery.winningPrize }}</span
                >
                <span v-else style="color: green">{{
                  item.lottery.winningPrize
                }}</span>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="12">
            <p style="font-size: 24px">
              จำนวนเงินทั้งหมด: {{ tranxDetail.totalPrice }} บาท
            </p>
          </v-col>
        </v-row>

        <v-card
          outlined
          class=""
          v-if="
            tranxDetail.status != 'ยกเลิก' && tranxDetail.status != 'SUCCESS'
          "
        >
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="pt-6 text-center">
              <span style="font-size: 30px; color: #4ca3ad">
                <u>หลักฐานการโอนเงิน</u>
              </span>
            </v-col>
            <v-col cols="12" class="text-center">
              <span style="font-size: 20px">
                ธนาคาร: ธนาคารกสิกรไทย KBANK
              </span>
              <br />
              <span style="font-size: 20px"> ชื่อบัญชี: อาทิมา พุธวัฒนะ </span>
            </v-col>

            <v-col cols="12" class="text-center">
              <span style="font-size: 28px"> เลขที่บัญชี: 0763756998 </span>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn text outlined style="border-color: #4ca3ad">
                <span style="font-size: 20px; color: #4ca3ad"> คัดลอก </span>
                <v-icon @click="copyBankNo()" style="color: #4ca3ad"
                  >mdi-clipboard-multiple-outline
                </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="pt-6 text-center mt-6">
              <span>อัพโหลดรูปสลิปใหม่</span>
            </v-col>
            <v-col cols="11" class="mt-5 ma-10" id="step-3">
              <v-row align="center" justify="center">
                <v-btn
                  @click="changePic1()"
                  text
                  outlined
                  color="white"
                  style="height: 80px; border-color: #4ca3ad"
                >
                  <v-img
                    v-model="picture_1"
                    src="@/assets/logo/Logo1.png"
                    class="mr-3"
                    max-width="70"
                  >
                  </v-img>
                  <span style="color: black; font-size: 20px"
                    >แนบสลิปใหม่ที่นี่</span
                  >
                </v-btn>
              </v-row>
              <v-row align="center" justify="center" class="mt-4">
                <v-col cols="12" md="12">
                  <v-card dense light class="pa-1" v-if="showImage">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-icon small light @click="RemoveImage()"
                        >mdi-close</v-icon
                      >
                    </v-card-actions>

                    <v-img
                      :src="showImage"
                      max-width="400"
                      class="mx-auto"
                      aspect-ratio="0.9"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
              <input
                type="file"
                ref="image"
                id="picTure1"
                accept="*/*"
                @change="showPicture1($event)"
                style="display: none"
                hidden
            /></v-col>
          </v-row>

          <!-- <v-row align="center" justify="center" class="mb-4">
            <v-btn
              @click="afterTransaction()"
              style="
                background: linear-gradient(180deg, #4ca3ad 0%, #008080 100%);
              "
              :disabled="!showImage"
            >
              <span style="color: white">อัพโหลดสลิปใหม่</span>
            </v-btn>
          </v-row> -->
        </v-card>
        <v-card outlined class="mt-6" v-else>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="pt-6 text-center">
              <span style="font-size: 30px; color: #4ca3ad">
                <u>หลักฐานการโอนเงิน</u>
              </span>
            </v-col>
            <v-col cols="11" class="mt-5 ma-10" id="step-3">
              <v-row align="center" justify="center" class="mt-4">
                <v-col cols="12" md="12">
                  <v-card dense light class="pa-1" v-if="showImage">
                    <v-img
                      :src="showImage"
                      max-width="400"
                      class="mx-auto"
                      aspect-ratio="0.9"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-row>
    <v-row class="pa-4">
      <v-col class="text-center">
        <v-btn large @click="cancel()" style="background: #718096"
          ><span style="color: #fff">ย้อนกลับ</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
  <!-- createdAt: (...)
id: (...)
lotteryDate: (...)
lotteryNo: (...)
owner: (...)
price: (...)
set: (...)
stock: (...)
time: (...)
updatedAt: (...)
urlImage: (...) -->

  <!-- couponCode: null
createdAt: "2021-06-28T09:32:29.000Z"
deliveryAddress: null
deliveryStatus: "WAITING"
id: 211
isDelivery: false
lotteryDate: null
lotteryTransaction: [{…}]
status: "SUCCESS"
totalPrice: 80
updatedAt: "2021-06-28T09:32:33.000Z"
userId: 4 -->
</template>
<script>
import { Encode, Decode } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      bankNo: "0763756998",
      pic1: [],
      picture_1: "",
      showImage: "",
      imageName1: "",
      trasactionId: "",
      tranxDetail: [],
      lotteryTransaction: [],
      search: "",
      count: 0,
      headers: [
        {
          text: "ลำดับ",
          value: "count",
          align: "center",
        },
        {
          text: "รูป",
          value: "lottery.urlImage",
          align: "center",
          width: "250px",
        },
        {
          text: "หมายเลขลอตเตอรี่",
          value: "lottery.lotteryNo",
          align: "center",
        },
        {
          text: "จำนวน",
          value: "lottery.fixedStock",
          align: "center",
        },
        {
          text: "ถูกราลวัล",
          value: "winningPrize",
          align: "center",
        },
        {
          text: "ราคา",
          value: "lottery.price",
          align: "center",
        },
        {
          text: "งวดประจำวันที่",
          value: "lottery.lotteryDate",
          align: "center",
        },
      ],
      // createdAt: (...)
      // id: (...)
      // lotteryDate: (...)
      // lotteryNo: (...)
      // owner: (...)
      // price: (...)
      // set: (...)
      // stock: (...)
      // time: (...)
      // updatedAt: (...)
      // urlImage: (...)
    };
  },
  watch: {
    pic1(val) {
      if (val.length) {
        this.checkPaid();
      }
    },
  },
  created() {
    this.getTranxDetail();
  },
  methods: {
    goToProfile() {
      this.$router.push("/Profile");
    },
    copyBankNo() {
      navigator.clipboard.writeText(this.bankNo);
      this.$swal.fire({
        icon: "success",
        title: "คัดลอกสำเร็จ",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async checkPaid() {
      this.$swal({
        text: "ยืนยันการจอง/ส่งหลักฐานการโอนเงิน ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.totalPrice == 0) {
            this.$swal.fire({
              icon: "error",
              title: "ไม่มีสินค้าในตะกร้า",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push("/");
          } else if (this.totalPrice !== 0) {
            await this.uploadSlip();
          }
        } else {
          this.RemoveImage();
        }
      });
    },

    newWindow(val) {
      window.open(val);
    },
    async uploadSlip() {
      this.getTranxDetail().then(async () => {
        if (
          this.tranxDetail.status === "ยกเลิก" ||
          this.tranxDetail.status === "SUCCESS"
        ) {
          await this.$swal.fire({
            icon: "warning",
            html: "รายการนี้ถูกยกเลิกหรือสำเร็จไปแล้ว<br/>ไม่สามารถอัพโหลดสลิปได้อีก",
            showConfirmButton: false,
            timer: 3000,
          });
          this.$router.go(-1);
        } else {
          var user = JSON.parse(Decode.decode(localStorage.getItem("RAUser")));
          this.trasactionId = localStorage.getItem("trasactionId");
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };

          const data = {
            status: "รอดำเนินการ",
            slipImg: this.pic1,
          };

          const response = await this.axios.put(
            `${process.env.VUE_APP_API}/transaction/update/` +
              this.tranxDetail.id,
            data,
            auth
          );

          if (response.data.response_status == "ERROR") {
            this.$swal.fire({
              icon: "warning",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.$swal.fire({
              icon: "success",
              // title: response.data.message,
              title: "อัพโหลดสลิปสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
            this.isPaid = true;
            this.$router.go(-1);
            localStorage.setItem("tSuccess", encode(this.trasactionId));
            localStorage.removeItem("trasactionId", this.trasactionId);
          }
        }
      });
    },

    // async afterTransaction() {
    //
    //   //

    //   this.$swal({
    //     text: "ยืนยันส่งหลักฐานการโอนเงิน ?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     cancelButtonText: "ยกเลิก",
    //     confirmButtonText: "ยืนยันส่งหลักฐานการโอนเงิน",
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       if (this.totalPrice == 0) {
    //         this.$swal.fire({
    //           icon: "error",
    //           title: "ไม่มีสินค้าในตะกร้า",
    //           showConfirmButton: false,
    //           timer: 1500,
    //         });
    //         this.$router.push("/");
    //
    //       } else if (!this.pic1 || this.pic1.length == 0) {
    //         this.$swal.fire({
    //           icon: "error",
    //           title: "กรุณาอัพรูปหลักฐานการโอนเงิน",
    //           showConfirmButton: false,
    //           timer: 1500,
    //         });
    //
    //         // } else if (this.totalPrice !== 0 ) {
    //       } else if (this.totalPrice !== 0 && this.pic1.length != 0) {
    //
    //         var user = JSON.parse(
    //           Decode.decode(localStorage.getItem("RAUser"))
    //         );
    //         // var paymcartsentData = JSON.parse(
    //         //   Decode.decode(localStorage.getItem("paymcartsentData"))
    //         // );
    //         // paymcartsentData.status = "PENDING";
    //         const auth = {
    //           headers: { Authorization: `Bearer ${user.token}` },
    //         };

    //         const data = {
    //           // status: paymcartsentData.status,
    //           // status: "PENDING",
    //           slipImg: this.pic1,
    //         };
    //
    //
    //         const response = await this.axios.put(
    //           `${process.env.VUE_APP_API}/transaction/update/` +
    //             this.trasactionId,
    //           data,
    //           auth
    //         );
    //
    //         if (response.data.response_status == "ERROR") {
    //           this.$swal.fire({
    //             icon: "warning",
    //             title: response.data.message,
    //             showConfirmButton: false,
    //             timer: 1500,
    //           });
    //         } else {
    //           this.$swal.fire({
    //             icon: "success",
    //             // title: response.data.message,
    //             title: "อัพโหลดสลิปสำเร็จ",
    //             showConfirmButton: false,
    //             timer: 1500,
    //           });
    //           this.$router.push("safe");
    //           localStorage.setItem(
    //             "tSuccess",
    //             Encode.encode(this.trasactionId)
    //           );
    //         }
    //       }
    //     }
    //   });
    //   //updateput trx trxid = status success
    // },
    cancel() {
      this.$router.go(-1);
    },
    convertDate(value) {
      if (value.updatedAt) {
        return moment(String(value.updatedAt)).format("MM/DD/YYYY HH:mm");
      }
    },
    async getTranxDetail() {
      var transactionDetail = JSON.parse(
        Decode.decode(localStorage.getItem("transactionDetail"))
      );
      this.trasactionId = transactionDetail;

      //   this.tranxDetail = transactionDetail;
      //   this.lotteryTransaction = transactionDetail.lotteryTransaction;
      //   this.userId = transactionDetail.userId || transactionDetail.user.id;
      var user = JSON.parse(Decode.decode(localStorage.getItem("RAUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/` + transactionDetail,
        auth
      );

      this.tranxDetail = response.data.data;
      this.showImage = response.data.data.slipImg;
    },
    showPicture1(e) {
      //
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          //
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      //
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage = "";
      this.imageName1 = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.table-header {
  thead {
    background-color: black;
  }
}
.theme--light.v-data-table {
  background-color: #ffffff00;
  color: rgba(0, 0, 0, 0.87);
}
.info2 {
  width: 40%;
  height: 5%;
  margin-top: -65px;
  margin-left: 90px;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 5%;
  max-height: 10%;
  // background: #f3e0bf73;
  // backdrop-filter: blur(4px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}
</style>

